
.Footer {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.7rem;
    padding-top: 20px;
    padding-bottom: 20px;
}

.Footer-link {
    color: white;
    text-decoration: none;
}

.Footer-link:hover {
    text-decoration: underline;
}
