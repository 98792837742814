
.MenuIcons {

}

@media all and (max-width: 800px) {
    .MenuIcons {
        display: none;
    }
    .MenuIcons-break {
        display: none;
    }
        
    .MenuIcons-button {
        font-size: 1rem;
    }
        
    .MenuIcons-img {
        width: 40px;
    }
}

.MenuIcons-button {
    background-color: transparent;
    cursor: pointer;
    border: none;
    color: white;
    margin-bottom: 12px;
    font-family: 'Amatic SC', cursive;
    font-weight: bold;
    font-size: 1.5rem;
}

.MenuIcons-button:hover {
    opacity: 0.8;
}
