
.Snow {
    overflow: hidden;
    height: 100%;
    position: absolute;
    width: 100%;
}

.Snow-flake {
    position: absolute;
    width: 10px;
    height: 10px;
    background: white;
    border-radius: 50%;
    top: -10px;
    left: 0;
    z-index: 0;
}
