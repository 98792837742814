
.SmallMenuIcons {
    display: none;
    padding-top: 10px;
}

@media all and (max-width: 800px) {
    .SmallMenuIcons {
        display: block;
    }
}

.SmallMenuIcons-img {
    width: 35px;
}

.SmallMenuIcons-button {
    background-color: transparent;
    cursor: pointer;
    border: none;
    color: white;
    margin-bottom: 12px;
    font-family: 'Amatic SC', cursive;
    font-weight: bold;
    font-size: 0.9rem;
}

.SmallMenuIcons-button:hover {
    opacity: 0.8;
}
