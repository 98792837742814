
.Stamps {

    position: absolute;
    background-color: white;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    max-width: 600px;
    z-index: 3;
}

.Stamps-background {
    position: absolute;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.Stamp-button {
    cursor: pointer;
    border: 5px solid #397e3b;
    width: 33.33%;
    overflow: hidden;
}

.Stamp-button:hover {
    opacity: 0.8;
}

.Stamps-cancel {
    background-color: transparent;
    cursor: pointer;
    color: #397e3b;
    font-family: 'Amatic SC', cursive;
    outline: none;
    font-size: 2.2rem;
    border: 0;
}

.Stamps-cancel:hover {
    opacity: 0.8;
}

@media all and (max-width: 800px) {
    .Stamps {
        width: 90%;
    }
}
