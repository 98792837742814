
.Letter {
    display: flex;
}

.Letter-textarea {
    background-color: white;
    color: black;
    font-family: 'Amatic SC', cursive;
    font-size: 2rem;
    line-height: 2.1rem;
    border: 0;
    outline: none;
    resize: none;
    min-width: 90vh;
    min-height: 80vh;
    padding: 0 10px;
    text-align: left;
    
    background-image: linear-gradient(rgb(255, 27, 27) 1px, transparent 1px);
    background-size: 100% 2.1rem;
    -webkit-print-color-adjust: exact !important;

    border: 20px solid green;
    border-image: repeating-linear-gradient(
        45deg,
        rgb(6, 84, 6),
        rgb(6, 84, 6) 30px,
        rgb(189 207 189) 30px,
        rgb(189 207 189) 60px
      )
      20;
    border-radius: 3px;
}

@media screen and (max-width: 800px) {
    
    .Letter {
        flex-direction: column;
    }
    .Letter-textarea {
        min-width: auto;
        width: 30vh;
    }
}
