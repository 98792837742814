
body {
    background-color: #e12323;
    overflow-x: hidden;
}

.Container {
    background-color: #e12323;
    width: 100%;
    position: relative;
    min-height: 100vh;
}

.Container-app {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
}
