.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-logo {
  pointer-events: none;
  margin-bottom: 10px;
}

@media all and (max-width: 800px) {
  .App-header {
    display: none;
  }
}

@media print {
  .App-header, .MenuIcons, .Footer, .Snow, .SmallMenuIcons {
    display: none !important;
  }

  .Letter {
    display: block !important;
    margin: 0px auto;
  }
  
  @page { margin: 0; }
  body { margin: 1.6cm; }
}